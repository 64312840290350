<template>
  <div>
    <div
      class="mb-1 d-flex justify-content-center saj-header"
    >
      {{ $t('Edit Title') }}

    </div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-form-group
          class="saj-title mt-1"
          :label="$t('Title')"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Title')"
            rules="required"
          >
            <b-form-input
              v-model="title"
              class="saj-subtitle"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <div
      class="d-flex justify-content-center"
      style="
              <!-- background:pink; -->
            "
    >
      <button
        class="btn btn-close m-1 saj-button"
        aria-label="Close modal"
        style="

            color: white;
            background: #ff0000;
          "
        @click="close()"
      >
        {{ $t("Back") }}
      </button>
      <button
        class="btn m-1 saj-button"
        aria-label="submit modal"
        style="

            color: white;
            background: #0b103c !important;
            border-color: #0b103c !important;
          "
        @click="editTitle()"
      >
        {{ $t("Submit") }}
      </button>
    </div>
  </div>
</template>
<script>
import {
  //   BButton,
  //   BModal,
  BForm,
  BFormInput,
  BFormGroup,
  //   BFormTextarea,
  //   BCard,
  //   BFormDatepicker,
  //   BRow,
  //   BCol,
  //   BCalendar,
  //   BCardText,
  VBTooltip,
} from "bootstrap-vue"
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
// import vSelect from 'vue-select'
import SAJToast from '@/component/saj-toastification.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    // BButton,
    // BModal,
    BForm,
    BFormInput,
    BFormGroup,
    // BFormTextarea,
    // BCard,
    // BFormDatepicker,
    // BCardCode,
    // vSelect,
    // BRow,
    // BCol,
    // BCalendar,
    // BCardText,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    pmID: {
      type: null,
      default: null,
    },
    getTitle: {
      type: null,
    },
  },

  data() {
    return {
      title: "",
      // pmID: null,
      required,
    }
  },
  mounted() {
    // this.pmID = this.id
    this.title = this.getTitle
  },
  methods: {
    close() {
      this.$emit('close')
    },
    editTitle() {
      this.$refs.simpleRules.validate().then(success => {
        if (success){
          const data = new FormData()
          data.append('title', this.title)
          data.append('pm_id', this.pmID)

          this.$axios.post(`${this.$baseUrl}/executive/updateProject`, data).then(response => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t(`Project's name successfully updated`)}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )

            this.$emit('close')
            this.$emit('update-title', response.data.data.title)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.eventtext {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.4px;
}
</style>
