<template>

  <div
    class="p"
    style="width: auto"
  >
    <h1 class="m-1 p-1 saj-header">
      {{ $t("Are you sure you want to delete") + '?' }}
      <!-- {{ pmID }} -->
    </h1>
    <div
      class="d-flex justify-content-center"
      style="
              <!-- background:pink; -->
            "
    >
      <button
        class="btn btn-close m-1 saj-button"
        aria-label="Close modal"
        style="
                color: white;
                background: #ff0000;
              "
        @click="close()"
      >
        {{ $t("Cancel") }}
      </button>
      <button
        class="btn m-1 saj-button"
        aria-label="submit modal"
        style="
                color: white;
                background: #0b103c !important;
                border-color: #0b103c !important;
              "
        @click="deleteProject()"
      >
        {{ $t("Confirm") }}
      </button>
    </div>
  </div>

</template>
<script>
import {
//   BButton,
//   BModal,
//   BForm,
//   BFormInput,
//   BFormGroup,
//   BFormTextarea,
//   BCard,
//   BFormDatepicker,
//   BRow,
//   BCol,
//   BCalendar,
//   BCardText,
} from "bootstrap-vue"
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
// import vSelect from 'vue-select'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    // BButton,
    // BModal,
    // BForm,
    // BFormInput,
    // BFormGroup,
    // BFormTextarea,
    // BCard,
    // BFormDatepicker,
    // BCardCode,
    // vSelect,
    // BRow,
    // BCol,
    // BCalendar,
    // BCardText,
  },
  props: {
    pmID: {
      type: null,
      default: null,
    },
  },

  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close')
    },
    deleteProject() {
      const data = new FormData()
      data.append('pm_id', this.pmID)
      this.$axios.post(`${this.$baseUrl}/executive/deleteProjectManagement`, data).then(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t(`Successfully deleted`)}.`,
              icon: 'Trash2Icon',
              variant: 'success',
              titleColor: '#000',
              iconBg: '#e80202',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.$emit('close')
        this.$emit('delete-title')
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.eventtext {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.4px;
}
</style>
