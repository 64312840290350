<template>
  <div>
    <!-- Add Project -->
    <div
      style=""
    >
      <b-button
        v-b-modal.create-title
        class="btn mt-1 mb-1 saj-button"
        variant="info"
        style=""
      >
        {{ $t("Add Project Title") }}
      </b-button>
    </div>
    <b-modal
      id="create-title"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <CreateTitle
        @reload-create="createProjectActivity"
        @close="$bvModal.hide('create-title')"
      />
    </b-modal>
    <!-- Add Project End-->
    <!-- Project Table -->
    <b-table
      show-empty
      :items="projectList"
      :fields="fields"
      responsive=""
      bordered
      class="mt-1 mb-1 saj-subtitle"
    >
      <!-- A custom formatted column -->
      <template #head()="data">
        <span
          class="saj-title"
          style=""
        >{{ $t(data.label) }}</span>
      </template>

      <template #empty="">
        <h4 style="text-align: center; font-style: italic">
          {{ $t('There are no records to show') }}
        </h4>
      </template>

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(name)="data">
        {{ data.item.title }}
      </template>

      <template #cell(action)="data">
        <div
          class="d-flex justify-content-center"
        >
          <!-- {{ data.item.id }} -->
          <div
            class=""
            @click="viewProjectMember(data.item.id, data.item.title)"
          >
            <feather-icon
              v-b-tooltip.hover.top="$t('View Member')"
              icon="EyeIcon"
              class="hoverIcon mr-1"
              size="25"
              style="cursor: pointer;"
            />
          </div>
          <feather-icon
            v-b-modal.edit-title
            v-b-tooltip.hover.top="$t('Edit')"
            icon="EditIcon"
            size="25"
            style="color: green"
            class="mr-1"
            @click="memberData = data, projectID = data.item.id, projectTitle = data.item.title"
          />
          <feather-icon
            v-b-modal.delete-title
            v-b-tooltip.hover.top="$t('Delete')"
            icon="Trash2Icon"
            size="25"
            style="color: red"
            @click="memberData = data, projectID = data.item.id"
          />
        </div> </template></b-table>

    <!-- {{ memberData.item.id }} -->
    <!-- Project Table End -->
    <b-modal
      id="edit-title"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <edit-title
        :pm-i-d="projectID"
        :get-title="projectTitle"
        @close="$bvModal.hide('edit-title')"
        @update-title="getProjManagement()"
      />
    </b-modal>
    <b-modal
      id="delete-title"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <delete-title
        :pm-i-d="projectID"
        @close="$bvModal.hide('delete-title')"
        @delete-title="getProjManagement()"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BModal,
  BTable,
  VBTooltip,
} from "bootstrap-vue"
import CreateTitle from "@/component/projectManagement/CreateTitle.vue"
import DeleteTitle from "@/component/projectManagement/Delete_Project.vue"
import EditTitle from "@/component/projectManagement/EditTitle.vue"
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BModal,
    BTable,
    CreateTitle,
    DeleteTitle,
    EditTitle,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      memberData: null,
      projectID: null,
      projectTitle: null,
      projectList: [],
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'name', label: 'Project Name', thClass: 'text-center' },
        { key: 'action', label: 'Action', thClass: 'text-center' },
      ],
    }
  },
  computed: {
    ...mapGetters(['userDetails']),
  },

  mounted() {
    this.getProjManagement()
  },
  methods: {
    getProjManagement() {
      this.$axios.get(`${this.$baseUrl}/getProjectManagement?user_id=${this.userDetails.user_id}`).then(response => {
        if (response.data.data.project_management_created.length !== 0){
          this.projectList = response.data.data.project_management_created
        }
      })
    },
    createProjectActivity(){
      this.getProjManagement()
    },
    viewProjectMember(id, projectTitle) {
      this.$router.push({ name: 'View-Member', params: { id, projectTitle } })
      // console.log(id, subsidiaryname)
    },
  },
}
</script>
