<template>
  <div>
    <b-card
      no-body
      class="saj-title mr-1"
      style=""
    >
      <b-card-header class="p-1">
        <h5
          class="saj-title"
          style=""
        >
          {{ $t("Performance List") }}
        </h5>
      </b-card-header>
      <b-card-body>
        <div class="col-lg">
          <div
            class="card"
            style="
            "
          >
            <b-tabs
              class="saj-title"
              style="width: 100%"
            >
              <b-tab
                :title="$t('Add Project')"
                active
              >
                <addProject />
              </b-tab>
              <b-tab :title="$t('Project Assigned')">
                <assignedProject />
              </b-tab>

            </b-tabs>

          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped>
.hoverIcon{
  color: purple;
  cursor: pointer;
}

.hoverIcon:hover{
  color: red;
}
</style>

<script>
import Ripple from "vue-ripple-directive"
import {
  BCard,
  BCardHeader,
  BCardBody,
  VBPopover,
  BPopover,
  VBTooltip,
  BTabs,
  BTab,
} from "bootstrap-vue"
import moment from "moment"
import addProject from '@/component/projectManagement/add-project.vue'
import assignedProject from '@/component/projectManagement/assigned-project.vue'
import { mapGetters } from 'vuex'
// import SAJToast from "@/component/saj-toastification.vue"

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    // BDropdown,
    // BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BPopover,
    BTabs,
    BTab,
    addProject,
    assignedProject,
  },
  directives: {
    "b-popover": VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },

  watch: {

    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.iniStatus = []
      this.iniStatus.push({ text: `${this.$t('Choose Status')}`, value: null, disabled: true })
      if (this.statusList !== undefined){
        this.statusList.forEach(k => {
        // console.log('localee', this.$i18n.t(k))
          this.iniStatus.push({
            text: this.$i18n.t(k),
            value: k,
          })
        })
      }
      if (this.YearList !== undefined){
        this.YearList = this.YearList.map(x => {
          if (x.value === ''){
            return {
              ...x,
              text: this.$t('Choose Year'),
            }
          }
          return x
        })
      }
    },

  },
  mounted() {
    // console.log('curr locale', this.$i18n.locale)
  },
  methods: {

    // eslint-disable-next-line no-unused-vars
    nextPage(perfID, stats){
      // const id = this.userId
      const ids = perfID
      this.$router.push({ name: 'my-performance', params: { id: ids, status: stats } })
    },

    DateChanged(date) {
      const newDay = moment(date, "").format("D-MM-yyyy")
      return newDay
    },

  },

}
</script>
<style>

</style>
