<template>
  <div>
    <!-- Assigned Project Table -->
    <!-- {{ employeeList }} -->
    <b-table
      show-empty
      :items="assignedProjectList"
      :fields="fields"
      responsive=""
      bordered
      class="mt-1 mb-1 saj-subtitle"
    >
      <!-- A custom formatted column -->
      <template #head()="data">
        <span
          class="saj-title"
          style=""
        >{{ $t(data.label) }}</span>
      </template>

      <template #empty="">
        <h4 style="text-align: center; font-style: italic">
          {{ $t('There are no records to show') }}
        </h4>
      </template>

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(project_name)="data">
        {{ data.item.title }}
      </template>
      <template #cell(action)="data">
        <div
          class="d-flex justify-content-center"
        >
          <div
            class=""
            @click="viewProjectMember(data.item.id, data.item.title)"
          >
            <!-- {{ data.item.id }} -->
            <feather-icon
              v-b-tooltip.hover.top="$t('View Member')"
              icon="EyeIcon"
              class="hoverIcon mr-1"
              size="25"
              style="cursor: pointer;"
            />
          </div>
        </div>
      </template>
    </b-table>
    <!-- Assigned Project Table End -->
  </div>
</template>
<script>
import {
  // BButton,
  // BModal,
  BTable,
  VBTooltip,
} from "bootstrap-vue"
import { mapGetters } from 'vuex'

export default {
  components: {
    // BButton,
    // BModal,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      memberData: null,
      projectID: [],
      assignedProjectList: [],
      employeeList: [],
      projectTitle: null,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'project_name', label: 'Project Name', thClass: 'text-center' },
        { key: 'action', label: 'Action', thClass: 'text-center' },
      ],
    }
  },
  computed: {
    ...mapGetters(['userDetails']),
  },

  mounted() {
    this.getProjManagement()
  },
  methods: {
    getProjManagement() {
    //   const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/getProjectManagement?user_id=${this.userDetails.user_id}`).then(response => {
        if (response.data.data.project_management_view.length !== 0){
          this.assignedProjectList = response.data.data.project_management_view
        }
      })
    },
    createProjectActivity(){
      this.getProjManagement()
    },
    viewProjectMember(id, projectTitle) {
      this.$router.push({ name: 'View-Assigned-Member', params: { id, projectTitle } })
    },
  },
}
</script>
